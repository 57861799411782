<template>
  <section class="list container">
    <div id="results">
      <div v-if="items.length === 0">
        <header class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <h4 class="title">{{ title }}</h4>
            <br /><br /><br />
            {{ i18n['data-table-conversations'].tcNoItemsMessage }}
          </div>
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <button
              class="btn btn-primary flex-0 d-block w-100-sd"
              v-if="addItems.display && includeAddButton"
              @click="triggerAction(addItems.action)"
            >
              {{ addItems.text }}
            </button>
          </div>
        </header>
      </div>
      <div v-if="items.length > 0">
        <header v-if="toggleMe && !hideHeader" class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd" v-if="title">
            <h4 class="title">{{ title }}</h4>
          </div>
          <div class="d-flex align-items-left flex-wrap flex-sm-nowrap w-100-sd">
            <div v-show="showExportBtn">
              <a class="btn btn-dark flex-0 mr-2" download @click.prevent="downloadReport(csvUrl, reportName, 'csv')">{{
                i18n['data-table-conversations'].tcExportCSV
              }}</a>
              <a class="btn btn-dark flex-0 mr-2" download @click.prevent="downloadReport(pdfUrl, reportName, 'pdf')">{{
                i18n['data-table-conversations'].tcExportPDF
              }}</a>
            </div>
            <b-form-input
              v-model="filter"
              @input="handleSearchTermChange"
              :placeholder="i18n['data-table-conversations'].tcSearch"
              class="search w-100-sd mw-100-sd mb-3 mb-sm-0 d-block w-100-sd order-1 order-sm-2"
            ></b-form-input>
          </div>
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <button
              class="btn btn-primary flex-0 d-block w-100-sd"
              v-if="addItems.display && includeAddButton"
              @click="triggerAction(addItems.action)"
            >
              {{ addItems.text }}
            </button>
          </div>
        </header>
        <main v-if="toggleMe" class="table-responsive">
          <b-table
            class="c-table"
            :fields="fields"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            sort-icon-left
            small
          >
            <template v-slot:cell(viewCount)="data" data="icon" >
              {{ data.value }}
            </template>
            <template v-slot:cell(mtg_start_date)="data">
              <a href="#" style="underline" @click.prevent="conversationKeyStoreAndRedirect(data.item.mtg_key)">{{
                data.item.mtg_start_date.substring(0,10)
              }}</a>
            </template>
            <template v-slot:cell(history_ind_key)="data">
              <a
                href="#"
                style="underline"
                @click.prevent="instructorHistoryStoreKeyAndRedirect(data.item.history_ind_key)"
                >{{ i18n['data-table-conversations'].tcViewHistory }}</a
              >
            </template>
            <template v-slot:cell(history_eu_ind_key)="data">
              <a
                href="#"
                style="underline"
                @click.prevent="educationalUnitHistoryStoreKeyAndRedirect(data.item.history_eu_ind_key)"
                >{{ i18n['data-table-conversations'].tcViewHistory }}</a
              >
            </template>
          </b-table>
        </main>
        <footer v-if="toggleMe" class="d-flex align-items-center justify-content-between">
          <div class="prev" @click="prevPage" :class="{ hidden: this.currentPage == 1 }">
            <i-arrow-left />
          </div>
          <nav class="d-flex align-items-center justify-content-center">
            <div class="show d-flex align-items-center justify-content-end">
              <div class="lower">{{ i18n['data-table-conversations'].tcShow }}</div>
              <button :class="{ active: this.perPage == 20 }" @click="updateNumber(20)">20</button>
              <button :class="{ active: this.perPage == 50 }" @click="updateNumber(50)">50</button>
              <button :class="{ active: this.perPage == 80 }" @click="updateNumber(80)">80</button>
            </div>
            <div class="sep"></div>
            <div class="pagination justify-self">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                :hide-goto-end-buttons="true"
                @change="pageChanged"
              ></b-pagination>
            </div>
          </nav>
          <div
            class="next"
            @click="nextPage"
            :class="{
              hidden: Math.ceil(this.items.length / this.perPage) == this.currentPage
            }"
          >
            <i-arrow-right />
          </div>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import iArrowLeft from '@/assets/svgs/arrow-left.vue'
import iArrowRight from '@/assets/svgs/arrow-right.vue'
import iCSV from '@/assets/svgs/iCSV.vue'
import iExcel from '@/assets/svgs/iExcel.vue'
import iFile from '@/assets/svgs/iFile.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import ServiceDelete from '@/assets/svgs/service-card-delete.vue'
import ServiceEdit from '@/assets/svgs/service-card-edit.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'

export default {
  name: 'data-table-conversations',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'data-table-conversations': {
            tcDownload: 'Download',
            tcNoItemsMessage: 'No records were found.',
            tcShow: 'Show',
            tcTitle: 'Title',
            tcType: 'Type',
            tcUpdated: 'Updated',
            tcViewHistory: 'View History'
          }
        }
      }
    },
    fields: {
      type: Array,
      default: []
    },
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    tooltip: {
      type: String,
      default: ''
    },
    showExportBtn: {
      type: Boolean,
      default: true
    },
    hideHeader: { type: Boolean, default: false },
    title: String,
    toggle: {
      type: Boolean,
      default: false
    },
    addItems: {
      type: Object,
      default: () => ({
        text: 'Add Instructor',
        display: true,
        action: '#'
      })
    },
    includeAddButton: { type: Boolean, default: false }
  },
  data() {
    return {
      // noItemsMessage: 'No records were found.',
      // Table Settings
      sortBy: 'Name',
      sortDesc: false,
      perPage: 20,
      currentPage: 1,
      filter: null,
      number: 20,
      pag: '',
      toggleMe: true
    }
  },
  methods: {
    ...mapActions({
      clearIndividualHistory: 'conversations/clearIndividualHistory',
      setSearchTerm: 'search/setSearchTerm',
      setSelectedEducationalUnitKey: 'conversations/setSelectedEducationalUnitKey',
      setSelectedInstructorKey: 'conversations/setSelectedInstructorKey',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setSelectedMeetingKey: 'user/setSelectedMeetingKey'
    }),
    pageChanged() {
      document.getElementById('results').scrollIntoView()
    },
    updateNumber(num) {
      this.perPage = num
    },
    prevPage() {
      if (this.currentPage == 1) {
        return
      }
      this.currentPage--
      document.getElementById('results').scrollIntoView()
    },
    nextPage() {
      if (Math.ceil(this.items.length / this.perPage) == this.currentPage) {
        return
      }
      this.currentPage++
      document.getElementById('results').scrollIntoView()
    },
    triggerAction(action) {
      this.$emit(action)
    },
    handleVideoClick(data) {
      this.$emit('video_click', data)
    },
    getImageLink(mediaid) {
      mediaid = mediaid.replace('{', '')
      mediaid = mediaid.replace('}', '')
      return this.mrpSiteCoreBaseURL + '/getimage/' + mediaid
    },
    formatUpdatedDate(updatedDate) {
      const justDate = updatedDate.substring(0, 8)
      const thisYear = justDate.substring(0, 4)
      const thisMonth = justDate.substring(4, 6)
      const thisDay = justDate.substring(6, 8)
      const newDateObj = `${thisMonth}-${thisDay}-${thisYear}`
      return newDateObj
    },
    topicLinkClick(data) {
      this.$emit('topic_click', data)
    },
    videoLinkClick(data) {
      this.$emit('video_click', data)
    },
    async handleSearchTermChange() {
      let obj = `{ "${this.searchTerm}": "${this.filter}" }`
      await this.setSearchTerm(JSON.parse(obj))
    },
    async conversationKeyStoreAndRedirect(data) {
      await this.setSelectedMeetingKey(data)
      this.$router.push({ path: '/programs/pw/conversations/info' })
    },
    async instructorHistoryStoreKeyAndRedirect(data) {
      await this.setSelectedInstructorKey(data)
      await this.setSelectedIndividualKey(data)      
      await this.clearIndividualHistory()
      this.$router.push({ path: '/programs/pw/conversations/individual-history' })
    },
    async educationalUnitHistoryStoreKeyAndRedirect(data) {
      await this.setSelectedEducationalUnitKey(data)
      await this.setSelectedIndividualKey(data)
      this.$router.push({ path: '/programs/pw/conversations/individual-history' })
    }
  },
  computed: {
    ...mapGetters({
      mrpSelectedVideoId: 'mrp/mrpSelectedVideoId',
      mrpSiteCoreBaseURL: 'mrp/mrpSiteCoreBaseURL',
      searchTerms: 'search/searchTerms',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
    }),
    rows() {
      return this.items ? this.items.length : 0
    }
  },
  created() {
    this.perPage = constantData.per_page_default
    this.filter = this.searchTerms.conversationsTerm
  },
  components: {
    iArrowLeft: iArrowLeft,
    iArrowRight: iArrowRight,
    iCSV: iCSV,
    iExcel: iExcel,
    iFile: iFile,
    iTooltip: iTooltip,
    ServiceDelete: ServiceDelete,
    ServiceEdit: ServiceEdit,
    ToggleArrow: ToggleArrow
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/views/ChurchList.scss';

.container {
  padding-left: 0;
  padding-right: 0;
}

#results {
  box-shadow: none !important;
}

.title {
  padding-left: 0px;
}

.video {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.downloadIcon {
  max-width: 30px;
}

.video-name {
  font-family: $open-sans;
  font-size: 16px;
  font-weight: 800;
  display: inline;
}

.now-playing {
  color: $primary-color;
}

.preheader {
  padding: 30px 32px 15px;
  align-items: center;

  @include breakpoint(sm) {
    padding-left: 23px;
    padding-right: 23px;
  }

  h2 {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4;
    line-height: 44px;
    text-transform: uppercase;

    @include breakpoint(sm) {
      padding-top: 0;
    }
  }
}

.i-tooltip {
  vertical-align: super;

  svg path {
    fill: #000 !important;
  }
}

.service-action-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > div {
    margin-right: 10px;

    &:hover {
      opacity: 0.6;
    }

    svg {
      cursor: pointer;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}
</style>

<style lang="scss">
@import '@/styles/settings.scss';

.c-table {
  .non-wrap {
    white-space: nowrap;
  }

  tr {
    &:hover {
      td {
        background-color: $gray-150;
        color: $primary-color;
      }
    }
  }

  th {
    padding-left: 20px;
  }

  th,
  td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    border-top: none;
    vertical-align: middle;
    color: $gray-300;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  td {
    padding: 20px 0 20px 20px;
  }

  td.mrpIconClass {
    max-width: 50px;
    width: 50px;
    box-sizing: border-box;
    padding-right: 10px;
  }

  td.mrpVideoIconClass {
    max-width: 150px;
    box-sizing: border-box;
  }

  td.mrpSearchVideoIconClass {
    max-width: 150px;
    box-sizing: border-box;
    text-align: center;
  }

  td.mrpDownloadClass {
    max-width: 50px;
    width: 20px;
    padding-right: 30px;
    text-align: right;
    box-sizing: border-box;
  }

  
  a {
    color: $primary-color;
    text-decoration: underline;

    &:hover {
      color: $secondary-color;
    }
  }
}

.lower {
  text-transform: lowercase;
}
</style>
